header {
  padding: 10px;
  background-color: #fff;
  justify-content: space-between;
  height: 60px;
}
.container {
  background-color: #f5f5f5;
}

.logout-div {
  font-size: 12px;
  margin-left: 10px;
  padding: 3px 0 3px 8px;
  border-left: 1px solid #c0c0c0;
  font-weight: 600;
}
.logout-div span {
  margin-left: 3px;
}
.logout-div button {
  display: flex;
  background: none;
}
.webchat-container {
  height: calc(100% - 60px);
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}
.webchat-container ul > li > div {
  height: 100%;
}
/* .webchat-container li[aria-roledescription="attachment"] img {
  height: 150px;
} */
.suggestion-button {
  background: none;
  position: absolute;
  bottom: 30px;
  right: 42px;
}
.suggestion-icon {
  stroke: #767676;
}
.suggestion-icon:hover {
  stroke: #333;
}
.suggestion-button:focus > .suggestion-icon {
  stroke: #333;
}
.carousel-card {
  justify-content: space-between;
  padding: 15px;
}
.carousel-card h2 {
  padding: 10px 0;
  font-size: 1.2rem;
}
.carousel-card img {
  display: block;
  margin: auto;
  width: 220px;
  height: 200px;
}
.carousel-card p {
  margin: 10px 0;
}
.carousel-card p:first-of-type {
  font-size: 1rem;
}
.ad-card-button {
  background-color: #fff;
  color: #0063b1;
  border: 1px solid;
  padding: 10px;
  font-weight: 600;
  white-space: nowrap;
}
.ad-card-button:focus {
  background-color: #0063b1;
  color: #fff;
}
.carousel-card-sec-text {
  color: #767676;
  font-size: 0.88rem;
}
.feedback-card {
  padding: 10px;
}
.feedback-card button {
  margin: 5px 0 5px 0;
}
.feedback-section {
  margin-top: 20px;
}
.feedback-section textarea {
  font-family: inherit;
  min-height: 80px;
  padding: 10px;
}
.feedback-section textarea:focus {
  outline: 1px solid grey;
}
.webchat-container form input {
  margin-right: 20px;
}
.modal-popup {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
}
.session-exp-popup h2 {
  margin-bottom: 10px;
}
.session-exp-popup div:first-child {
  margin-top: 10px;
  margin-left: 10px;
}
.popup-main-content {
  margin: auto;
  align-self: center;
  font-size: 1.1rem;
  text-align: center;
  padding: 0 20px;
}
.popup-main-content button {
  margin: 20px;
}
input.radio_button {
  margin: 10px;
  width: 20px;
  height: 15px;
}
.comment-button-disable {
  border: none;
  padding: 10px;
  font-weight: 600;
  white-space: nowrap;
  color: #b7b7b7;
  cursor: not-allowed;
  background-color: #e2e2e2;
  opacity: 0.7;
}
.ratingcard-error-message
{
  color: red;
}
