*,
*:before,
*:after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
ol,
ul {
  padding: 20px 0 0 30px;
}
li {
  margin-bottom: 10px;
}
ul {
  list-style-type: disc;
}
:root {
  --input-padding: 0.5rem 0.7rem;
}
#root,
html,
body {
  height: 100%;
  font-size: 14px;
}
body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  outline: none;
  border: none;
  cursor: pointer;
}
.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}
.flex-row {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-align-center {
  align-items: center;
}
.flex-just-center {
  justify-content: center;
}
.input-group {
  margin-bottom: 15px;
}
.input {
  border: 1px solid #00000033;
  background-color: #fff;
  font-family: "Quicksand", sans-serif;
  padding: var(--input-padding);
  outline: none;
}
.input:focus {
  border: 1px solid #808080;
}
.button {
  cursor: pointer;
  font-family: inherit;
  padding: 8px;
  border-radius: 100px;
  display: inline-block;
  border: none;
  font-weight: 500;
  font-size: 1rem;
}
.webchat-container button:disabled {
  color: #b7b7b7;
  cursor: not-allowed;
  background-color: #e2e2e2;
  opacity: 0.7;
  border: none;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: inherit;
  opacity: 1; /* Firefox */
  font-size: 0.8rem;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: inherit;
  font-size: 1rem;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: inherit;
  font-size: 1rem;
}
.sr {
  position: absolute;
  left: -9999px;
}
.text-center {
  text-align: center;
}
.relative {
  position: relative;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.error {
  color: red;
  font-size: 1rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 960px) {
  .input {
    padding: 0.7rem;
  }
}
@media screen and (min-width: 1600px) {
  html {
    font-size: 18px;
  }
  .input {
    padding: 1rem 1.2rem;
  }
  .button {
    font-size: 1.5rem;
  }
}
