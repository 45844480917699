.container {
  position: absolute;
  bottom: 60px;
  right: 40px;
  background: white;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.container h4 {
  padding: 5px;
}
.button {
  cursor: pointer;
  background: #fff;
  padding: 10px;
}
.button:hover {
  background-color: #e2e2e2 !important;
}
