.username {
  font-size: 1rem;
  margin-right: 0.5rem;
  font-weight: 500;
}
.container > div {
  margin-right: 10px;
}
.logotext {
  font-size: 1.7rem;
  font-weight: 700;
}

@media screen and (min-width: 1500px) {
  .username {
    font-size: 1.5rem;
  }
  /* .container > img {
    width: 120px;
    height: 60px;
  } */
}
